/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse, create } from 'apisauce'

import URIs from './uri'
import { createApiClient } from './clients'
import { SessionItemType } from 'types/PaymentSessionType'
import { ClientType } from 'types/ApiType'

export const fetchClientInfo = async () => {
  try {
    const api = create({ baseURL: 'https://www.cloudflare.com' })
    const response = await api.get('/cdn-cgi/trace')

    if (response.ok) {
      const data: string = response.data as string
      const arr: string[] = data.split('\n')
      //initialize with open parenthesis
      let jsonString = '{'
      arr.forEach((i) => {
        if (i.length > 0 && i.includes('=')) {
          const keyValue: string[] = i.split('=')
          if (keyValue.length === 2) {
            jsonString = jsonString.concat(
              `"${keyValue[0]}": "${keyValue[1]}",`
            )
          }
        }
      })

      //remove extra comma
      jsonString = jsonString.slice(0, -1)

      //add close parenthesis
      jsonString = jsonString.concat('}')

      return JSON.parse(jsonString)
    } else {
      return {
        ip: null,
        loc: null,
        uag: null,
      }
    }
  } catch (err: any) {
    if (err.response) return err.response
    return null
  }
}

const getLink = async (
  key: string,
  linkId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.get(URIs.LINK.replace('$1', linkId))
}

const payLink = async (
  key: string,
  linkId: string,
  items: SessionItemType[],
  client: ClientType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.post(URIs.LINK_PAY.replace('$1', linkId), {
    line_items: items,
    client,
  })
}

const getLinkSession = async (
  key: string,
  linkId: string,
  sessionId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.get(
    URIs.LINK_SESSION.replace('$1', linkId).replace('$2', sessionId)
  )
}

const payLinkSession = async (
  key: string,
  linkId: string,
  sessionId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.post(
    URIs.LINK_SESSION_PAY.replace('$1', linkId).replace('$2', sessionId),
    {}
  )
}

const cancelLinkSession = async (
  key: string,
  linkId: string,
  sessionId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient(key)
  return await api.post(
    URIs.LINK_SESSION_CANCEL.replace('$1', linkId).replace('$2', sessionId),
    {}
  )
}

export default {
  fetchClientInfo,
  getLink,
  payLink,
  getLinkSession,
  payLinkSession,
  cancelLinkSession,
}
