const URIs = {
  LINK: '/links/$1',
  LINK_PAY: '/links/$1/buy',
  LINK_SESSION: '/links/$1/transactions/$2',
  LINK_SESSION_PAY: '/links/$1/transactions/$2/pay',
  LINK_SESSION_CANCEL: '/links/$1/transactions/$2/cancel',
}

export const APPLICATION_KEY = 'BAoQ6DGAUiXdwNWONTYiQow7OX2XRZed'

export default URIs
