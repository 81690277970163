import {ApiResponse} from 'apisauce';

const apiMonitor = (response: ApiResponse<any>) => {
  response.ok
    ? console.log(
        response.config?.method?.toUpperCase() + ' ' + response.config?.url,
        '[' + response.status + '] ' + JSON.stringify(response.data),
      )
    : console.log(
        response.config?.method?.toUpperCase() + ' ' + response.config?.url,
        '[' +
          response.status +
          '] ' +
          response.problem +
          ' ' +
          JSON.stringify(response.data),
      );
  //console.log(response.data);
};

export default apiMonitor;
